import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './LeadCaptureModal.css';

const LeadCaptureModal = ({ 
    show = false,
    onClose, 
    onSubmit,
    title = "Stay Updated!", 
    description = "Get exclusive access to the best advertising opportunities!",
    isPackageSelection = false,
    source = 'exit_intent'
}) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    if (!show) {
        return null;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!email || !name || !phone) {
            setError('Please fill in all fields');
            return;
        }

        // Basic phone number validation
        const phoneRegex = /^\d{10}$/;  // Simplified to exactly 10 digits
        if (!phoneRegex.test(phone.replace(/[\s-]/g, ''))) {  // Remove spaces and hyphens before testing
            setError('Please enter a valid 10-digit phone number');
            return;
        }

        try {
            const apiUrl = process.env.NODE_ENV === 'production'
                ? 'https://placead-website-1.onrender.com/api/leads'
                : 'http://localhost:5001/api/leads';

            const requestData = { 
                email, 
                name, 
                phone: phone.replace(/[\s-]/g, ''), // Remove spaces and hyphens before sending
                source: source
            };

            console.log('Sending lead data:', requestData);

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Origin': process.env.NODE_ENV === 'production' ? 'https://placead-website-1.onrender.com' : 'http://localhost:3000'
                },
                body: JSON.stringify(requestData),
                credentials: 'include'
            });

            if (!response.ok) {
                const errorText = await response.text();
                console.error('Server response:', errorText);
                try {
                    const errorData = JSON.parse(errorText);
                    setError(errorData.error || 'Something went wrong. Please try again.');
                } catch (parseError) {
                    console.error('Error parsing error response:', parseError);
                    setError('Something went wrong. Please try again.');
                }
                return;
            }

            let data;
            try {
                const responseText = await response.text();
                data = JSON.parse(responseText);
                console.log('Success response:', data);
            } catch (parseError) {
                console.error('Error parsing success response:', parseError);
                setError('Invalid response from server');
                return;
            }

            setSubmitted(true);
            localStorage.setItem('leadCaptured', 'true');
            
            // Reset form
            setEmail('');
            setName('');
            setPhone('');
            setError('');
            
            if (onSubmit) {
                onSubmit(requestData);
            }
            
            setTimeout(() => {
                setSubmitted(false);
                onClose();
            }, 3000);
        } catch (err) {
            console.error('Error submitting lead:', err);
            setError('Something went wrong. Please try again.');
        }
    };

    const handleClose = () => {
        // Reset form state
        setEmail('');
        setName('');
        setPhone('');
        setError('');
        setSubmitted(false);
        onClose();
    };

    return (
        <div className="lead-capture-overlay" onClick={(e) => {
            if (e.target.className === 'lead-capture-overlay') {
                handleClose();
            }
        }}>
            <div className="lead-capture-modal">
                <button className="close-button" onClick={handleClose} aria-label="Close dialog">
                    <FontAwesomeIcon icon={faTimes} size="lg" className="close-icon" />
                </button>
                <div className="modal-content">
                    <FontAwesomeIcon icon={faEnvelope} className="envelope-icon" />
                    <h2>{title}</h2>
                    <p>{description}</p>
                    {!submitted ? (
                        <>
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        placeholder="Your Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        placeholder="Your Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="form-input"
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        placeholder="Your Phone Number"
                                        value={phone}
                                        onChange={(e) => setPhone(e.target.value)}
                                        className="form-input"
                                        pattern="[\d\s+-]{10,15}"
                                        title="Please enter a valid phone number (10-15 digits, can include +, spaces, or hyphens)"
                                    />
                                </div>
                                {error && <div className="error-message">{error}</div>}
                                <button type="submit" className="submit-button">
                                    {isPackageSelection ? 'Proceed with Package' : 'Get Access'}
                                </button>
                            </form>
                            <p className="privacy-note">
                                We respect your privacy and will never share your information.
                            </p>
                        </>
                    ) : (
                        <div className="success-message">
                            <h3>Thank you for your interest!</h3>
                            <p>{isPackageSelection 
                                ? "Our team will contact you shortly to discuss your selected package." 
                                : "You'll receive updates about our latest advertising opportunities."}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default LeadCaptureModal;
