import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheck,
    faChartLine,
    faBullseye,
    faUsers,
    faPercentage,
    faMoneyBillWave,
    faClock,
    faChartPie,
    faArrowRight,
    faWrench,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import './CampaignPackages.css';
import LeadCaptureModal from './LeadCaptureModal';

const CampaignPackages = ({ packages = [], onSelectPackage }) => {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [showLeadModal, setShowLeadModal] = useState(false);
    const [showCustomForm, setShowCustomForm] = useState(false);
    const [isCustomPackage, setIsCustomPackage] = useState(false);
    const [customFormData, setCustomFormData] = useState({
        name: '',
        email: '',
        phone: '',
        budget: '',
        requirements: ''
    });

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: 0
        }).format(amount);
    };

    const formatMetric = (value, suffix = '') => {
        if (value >= 1000000) {
            return `${(value / 1000000).toFixed(1)}M${suffix}`;
        } else if (value >= 1000) {
            return `${(value / 1000).toFixed(1)}K${suffix}`;
        }
        return `${value}${suffix}`;
    };

    const handlePackageSelect = (pkg) => {
        setSelectedPackage(pkg);
        setIsCustomPackage(false);
        setShowLeadModal(true);
    };

    const handleCustomPackageClick = () => {
        setIsCustomPackage(true);
        setSelectedPackage(null);
        setShowLeadModal(true);
    };

    const handleLeadModalClose = () => {
        setShowLeadModal(false);
        setIsCustomPackage(false);
        if (selectedPackage) {
            onSelectPackage(selectedPackage);
        }
    };

    const handleCustomFormChange = (e) => {
        const { name, value } = e.target;
        setCustomFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleCustomFormSubmit = async (e) => {
        e.preventDefault();
        handleCustomPackageClick(); // This will now open the lead modal instead
    };

    const getMediaTypeIcon = (type) => {
        switch (type) {
            case 'billboards':
                return faChartPie;
            case 'radio':
                return faUsers;
            case 'print':
                return faMoneyBillWave;
            case 'digital':
                return faChartLine;
            case 'social':
                return faUsers;
            case 'display':
                return faChartLine;
            default:
                return faBullseye;
        }
    };

    if (!packages || packages.length === 0) {
        return (
            <div className="no-packages">
                <p>Please select at least one media type and set your budget to view packages.</p>
            </div>
        );
    }

    return (
        <div className="packages-container">
            <h2>Choose Your Campaign Package</h2>
            <div className="packages-grid">
                {packages.map((pkg, index) => (
                    <div
                        key={index}
                        className={`package-card ${selectedPackage === pkg ? 'selected' : ''}`}
                        onClick={() => handlePackageSelect(pkg)}
                    >
                        <div className="package-header">
                            <h3>{pkg.name}</h3>
                            <div className="package-price">
                                <span className="price">{formatCurrency(pkg.totalCost)}</span>
                            </div>
                        </div>

                        <div className="package-content">
                            <div className="outcomes-section">
                                <h4>Estimated Outcomes</h4>
                                <div className="outcomes-grid">
                                    {pkg.metrics && (
                                        <>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faUsers} />
                                                <span className="outcome-label">Reach</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.reach || 0)}
                                                </span>
                                            </div>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faBullseye} />
                                                <span className="outcome-label">Engagement</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.engagement || 0)}
                                                </span>
                                            </div>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faPercentage} />
                                                <span className="outcome-label">Conversion</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.conversion || 0, '%')}
                                                </span>
                                            </div>
                                            <div className="outcome-item">
                                                <FontAwesomeIcon icon={faChartLine} />
                                                <span className="outcome-label">ROI</span>
                                                <span className="outcome-value">
                                                    {formatMetric(pkg.metrics.roi || 0, '%')}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            <div className="media-allocations">
                                <h4>Media Allocations</h4>
                                <div className="allocations-list">
                                    {pkg.mediaAllocations.map((media, idx) => (
                                        <div key={idx} className="allocation-item">
                                            <div className="media-info">
                                                <FontAwesomeIcon icon={getMediaTypeIcon(media.type)} />
                                                <span className="media-type">
                                                    {media.type.charAt(0).toUpperCase() + media.type.slice(1)}
                                                </span>
                                                <span className="allocation-amount">
                                                    {formatCurrency(media.allocation)}
                                                </span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="features-section">
                                <h4>Features</h4>
                                <ul className="features-list">
                                    {pkg.features.map((feature, idx) => (
                                        <li key={idx}>
                                            <FontAwesomeIcon icon={faCheck} />
                                            <span>{feature}</span>
                                        </li>
                                    ))}
                                </ul>
                                {pkg.IconComponent && (
                                    <div className="package-icon">
                                        <pkg.IconComponent />
                                    </div>
                                )}
                                <button className="select-package-btn">
                                    Select Package
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}

                {/* Custom Package Card */}
                <div className="package-card custom-package">
                    <div className="package-header">
                        <h3>Custom Package</h3>
                        <div className="package-price">
                            <span className="price-label">Tailored to Your Needs</span>
                        </div>
                    </div>

                    <div className="package-content">
                        {!showCustomForm ? (
                            <div className="custom-package-intro">
                                <div className="custom-package-icon">
                                    <FontAwesomeIcon icon={faWrench} />
                                </div>
                                <h4>Need a Custom Solution?</h4>
                                <p>Let us create a personalized package that perfectly matches your requirements.</p>
                                <button 
                                    className="select-package-btn"
                                    onClick={handleCustomPackageClick}
                                >
                                    Request Custom Package
                                    <FontAwesomeIcon icon={faArrowRight} />
                                </button>
                            </div>
                        ) : (
                            <form onSubmit={handleCustomFormSubmit} className="custom-package-form">
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="name"
                                        value={customFormData.name}
                                        onChange={handleCustomFormChange}
                                        placeholder="Your Name *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="email"
                                        name="email"
                                        value={customFormData.email}
                                        onChange={handleCustomFormChange}
                                        placeholder="Your Email *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="tel"
                                        name="phone"
                                        value={customFormData.phone}
                                        onChange={handleCustomFormChange}
                                        placeholder="Phone Number *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <input
                                        type="text"
                                        name="budget"
                                        value={customFormData.budget}
                                        onChange={handleCustomFormChange}
                                        placeholder="Estimated Budget *"
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="requirements"
                                        value={customFormData.requirements}
                                        onChange={handleCustomFormChange}
                                        placeholder="Tell us about your requirements *"
                                        required
                                    />
                                </div>
                                <div className="form-actions">
                                    <button type="button" className="cancel-btn" onClick={() => setShowCustomForm(false)}>
                                        Cancel
                                    </button>
                                    <button type="submit" className="submit-btn">
                                        Submit Request
                                        <FontAwesomeIcon icon={faEnvelope} />
                                    </button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>

            <LeadCaptureModal 
                show={showLeadModal}
                onClose={handleLeadModalClose}
                onSubmit={(leadData) => {
                    if (selectedPackage) {
                        onSelectPackage(selectedPackage);
                    }
                    setShowCustomForm(false);
                }}
                title={isCustomPackage ? "Request Custom Package" : "Get Started with Your Campaign"}
                description={isCustomPackage 
                    ? "Share your contact information and requirements. Our team will create a personalized package just for you!"
                    : "Fill in your details to proceed with your selected package. Our team will contact you shortly!"}
                source={isCustomPackage ? "custom_package" : "package_selection"}
            />
        </div>
    );
};

export default CampaignPackages;
