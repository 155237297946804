import axios from 'axios';

const baseURL = process.env.NODE_ENV === 'production'
    ? 'https://placead-website-1.onrender.com/api'
    : 'http://localhost:5001/api';

const api = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': process.env.NODE_ENV === 'production' ? 'https://placead-website-1.onrender.com' : 'http://localhost:3000'
    }
});

// Add request interceptor to include token
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Add response interceptor for better error handling
api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('API Error:', error.response || error);
        return Promise.reject(error);
    }
);

export default api;
