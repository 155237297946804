// AI-powered package recommendations generator

const validateInput = (mediaType, budget) => {
    if (!mediaType || !budget) {
        throw new Error('Media type and budget are required');
    }
    if (budget <= 0) {
        throw new Error('Budget must be greater than 0');
    }
};

const calculateMediaCosts = (mediaType, budget) => {
    try {
        validateInput(mediaType, budget);
        
        const costs = {
            base: {
                billboards: 100000,
                radio: 50000,
                print: 75000,
                digital: 60000,
                social: 40000,
                display: 35000
            }
        };

        if (!costs.base[mediaType]) {
            throw new Error(`Invalid media type: ${mediaType}`);
        }

        return {
            baseCost: costs.base[mediaType],
            totalCost: costs.base[mediaType] + (budget * 0.1)
        };
    } catch (error) {
        console.error('Error in calculateMediaCosts:', error);
        throw error;
    }
};

const getMediaEffectiveness = (mediaType, goal) => {
    const effectiveness = {
        awareness: {
            billboards: 0.9,
            radio: 0.7,
            print: 0.6,
            digital: 0.8,
            social: 0.85,
            display: 0.75
        },
        leads: {
            billboards: 0.5,
            radio: 0.6,
            print: 0.55,
            digital: 0.9,
            social: 0.95,
            display: 0.85
        },
        engagement: {
            billboards: 0.4,
            radio: 0.65,
            print: 0.5,
            digital: 0.95,
            social: 1.0,
            display: 0.9
        }
    };

    return effectiveness[goal]?.[mediaType] || 0.5;
};

const generateOutcomeMetrics = (pkg, goal) => {
    const baseMetrics = {
        reach: 0,
        engagement: 0,
        conversion: 0,
        roi: 0
    };

    pkg.mediaAllocations.forEach(({ type, allocation }) => {
        const effectiveness = getMediaEffectiveness(type, goal);
        switch (type) {
            case 'billboards':
                baseMetrics.reach += allocation * 10000 * effectiveness;
                baseMetrics.engagement += allocation * 100 * effectiveness;
                break;
            case 'radio':
                baseMetrics.reach += allocation * 8000 * effectiveness;
                baseMetrics.engagement += allocation * 200 * effectiveness;
                break;
            case 'print':
                baseMetrics.reach += allocation * 5000 * effectiveness;
                baseMetrics.engagement += allocation * 150 * effectiveness;
                break;
            case 'digital':
                baseMetrics.reach += allocation * 15000 * effectiveness;
                baseMetrics.engagement += allocation * 500 * effectiveness;
                break;
            case 'social':
                baseMetrics.reach += allocation * 20000 * effectiveness;
                baseMetrics.engagement += allocation * 800 * effectiveness;
                break;
            case 'display':
                baseMetrics.reach += allocation * 12000 * effectiveness;
                baseMetrics.engagement += allocation * 300 * effectiveness;
                break;
            default:
                break;
        }
    });

    baseMetrics.conversion = baseMetrics.engagement * 0.02;
    baseMetrics.roi = (baseMetrics.conversion * 1000) / pkg.totalCost;

    return baseMetrics;
};

const getPackageFeatures = (tierName) => {
    const features = {
        basic: [
            'Basic media mix optimization',
            'Standard reporting dashboard',
            'Monthly performance reviews'
        ],
        premium: [
            'Advanced media mix optimization',
            'Real-time reporting dashboard',
            'Weekly performance reviews',
            'A/B testing capabilities'
        ],
        enterprise: [
            'AI-powered media mix optimization',
            'Custom reporting dashboard',
            'Daily performance reviews',
            'Advanced A/B testing',
            'Dedicated campaign manager'
        ]
    };

    return features[tierName] || features.basic;
};

const generatePackages = ({ budget, mediaTypes, goal }) => {
    try {
        const tiers = ['basic', 'premium', 'enterprise'];
        const allocations = {
            basic: 0.8,
            premium: 0.9,
            enterprise: 1.0
        };

        return tiers.map(tier => {
            const tierBudget = budget * allocations[tier];
            
            // Calculate effectiveness scores for each media type
            const effectivenessScores = mediaTypes.map(type => ({
                type,
                score: getMediaEffectiveness(type, goal)
            }));
            
            // Calculate total effectiveness score
            const totalScore = effectivenessScores.reduce((sum, { score }) => sum + score, 0);
            
            // Allocate budget based on effectiveness scores
            const mediaAllocations = effectivenessScores.map(({ type, score }) => {
                try {
                    const allocationRatio = score / totalScore;
                    const allocation = tierBudget * allocationRatio;
                    const { totalCost } = calculateMediaCosts(type, allocation);
                    
                    return {
                        type,
                        allocation: Math.round(allocation),
                        cost: totalCost
                    };
                } catch (error) {
                    console.error(`Error generating package for ${type}:`, error);
                    return null;
                }
            }).filter(Boolean);

            if (mediaAllocations.length === 0) {
                return null;
            }

            const totalCost = mediaAllocations.reduce((sum, { cost }) => sum + cost, 0);
            const pkg = {
                name: `${tier.charAt(0).toUpperCase() + tier.slice(1)} Package`,
                tier,
                totalCost,
                mediaAllocations,
                features: getPackageFeatures(tier)
            };

            pkg.metrics = generateOutcomeMetrics(pkg, goal);
            return pkg;
        }).filter(Boolean);
    } catch (error) {
        console.error('Error generating packages:', error);
        return [];
    }
};

export { generatePackages };
