import React from 'react';
import './LegalPages.css';

function PrivacyPolicy() {
    return (
        <div className="legal-page">
            <div className="legal-container">
                <h1>Privacy Policy</h1>
                <div className="last-updated">Last Updated: {new Date().toLocaleDateString()}</div>
                
                <section>
                    <h2>1. Information We Collect</h2>
                    <p>At PlaceAd, we collect and process the following types of information:</p>
                    <ul>
                        <li>Personal identification information (Name, email address, phone number, etc.)</li>
                        <li>Business information</li>
                        <li>Usage data and analytics</li>
                        <li>Marketing preferences</li>
                    </ul>
                </section>

                <section>
                    <h2>2. How We Use Your Information</h2>
                    <p>We use the collected information for:</p>
                    <ul>
                        <li>Providing and improving our services</li>
                        <li>Processing your transactions</li>
                        <li>Sending you marketing communications (with your consent)</li>
                        <li>Analyzing usage patterns to improve user experience</li>
                    </ul>
                </section>

                <section>
                    <h2>3. Data Security</h2>
                    <p>We implement appropriate security measures to protect your personal information, including:</p>
                    <ul>
                        <li>Encryption of sensitive data</li>
                        <li>Regular security assessments</li>
                        <li>Access controls and authentication</li>
                        <li>Secure data storage practices</li>
                    </ul>
                </section>

                <section>
                    <h2>4. Your Rights</h2>
                    <p>You have the right to:</p>
                    <ul>
                        <li>Access your personal data</li>
                        <li>Request corrections to your data</li>
                        <li>Request deletion of your data</li>
                        <li>Withdraw consent for data processing</li>
                    </ul>
                </section>

                <section>
                    <h2>5. Contact Us</h2>
                    <p>If you have any questions about this Privacy Policy, please contact us at:</p>
                    <div className="contact-details">
                        <p>Email: privacy@placead.ai</p>
                        <p>Noid Sector 62,Candor Techpark</p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default PrivacyPolicy;
