// src/pages/HomePage.js
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlane,
    faFilm,
    faTv,
    faNewspaper,
    faRadio,
    faGlobe,
    faUsers,
    faMicrophone,
    faRunning,
    faSearch,
    faBuilding,
    faFileAlt,
    faBullhorn,
    faAd,
    faChartLine,
    faRocket,
    faMoneyBillWave,
    faEnvelope,
    faPhone,
    faLocationDot
} from '@fortawesome/free-solid-svg-icons';
import {
    faLinkedin,
    faTwitter,
    faFacebookF
} from '@fortawesome/free-brands-svg-icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './HomePage.css';
import CampaignPlanner from '../components/CampaignPlanner';
import FAQ from '../components/FAQ';

const MEDIA_TYPES = {
    'AIRLINE/AIRPORT': 'airport',
    'CINEMA': 'cinema',
    'DIGITAL': 'digital',
    'INFLUENCER': 'influencer-marketing',
    'MAGAZINE': 'magazine',
    'NEWSPAPER': 'newspaper',
    'BTL': 'nontraditional',
    'OUTDOOR': 'outdoor',
    'RADIO': 'radio',
    'SPORTS': 'sports',
    'TELEVISION': 'television',
    'AGENCY PARTNER': 'agency'
};

const MEDIA_CHANNELS = [
    { name: 'Television', icon: faTv, description: 'Reach millions through TV advertising' },
    { name: 'Radio', icon: faRadio, description: 'Connect with listeners nationwide' },
    { name: 'Digital', icon: faGlobe, description: 'Dominate the digital landscape' },
    { name: 'Print Media', icon: faNewspaper, description: 'Traditional print advertising excellence' },
    { name: 'Cinema', icon: faFilm, description: 'Captivate audience on the big screen' },
    { name: 'Outdoor', icon: faBullhorn, description: 'Make an impact with outdoor advertising' },
    { name: 'Airport', icon: faPlane, description: 'Premium airport advertising solutions' },
    { name: 'Influencer', icon: faUsers, description: 'Partner with top influencers' }
];

function HomePage({ onLoginClick, isSidebarOpen, setIsSidebarOpen }) {
    const history = useHistory();
    const [showCampaignPlanner, setShowCampaignPlanner] = useState(false);

    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 800,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
        arrows: true,
        initialSlide: 0,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true
                }
            }
        ]
    };

    const handleAgencyRegistration = () => {
        history.push('/agency-registration');
    };

    const handleCategoryClick = (category) => {
        const toolName = MEDIA_TYPES[category];
        if (toolName) {
            history.push(`/view-sites?toolName=${toolName}`);
        }
    };

    const handleQuickLaunch = () => {
        history.push('/campaign-planning');
    };

    const mediaTypes = [
        { icon: faPlane, label: 'AIRLINE/AIRPORT', description: 'Reach travelers worldwide' },
        { icon: faFilm, label: 'CINEMA', description: 'Engage captive audiences' },
        { icon: faGlobe, label: 'DIGITAL', description: 'Target online presence' },
        { icon: faUsers, label: 'INFLUENCER', description: 'Connect with communities' },
        { icon: faNewspaper, label: 'MAGAZINE', description: 'Premium print media' },
        { icon: faNewspaper, label: 'NEWSPAPER', description: 'Local & national reach' },
        { icon: faBullhorn, label: 'BTL', description: 'Direct consumer engagement' },
        { icon: faAd, label: 'OUTDOOR', description: 'High-visibility advertising' },
        { icon: faRadio, label: 'RADIO', description: 'Audio advertising solutions' },
        { icon: faRunning, label: 'SPORTS', description: 'Athletic event marketing' },
        { icon: faTv, label: 'TELEVISION', description: 'Broadcast advertising' },
        { icon: faMicrophone, label: 'AGENCY PARTNER', description: 'Strategic partnerships' }
    ];

    const features = [
        {
            title: 'Quick Launch',
            description: 'AI-powered campaign creation',
            icon: faRocket,
            action: handleQuickLaunch
        },
        {
            title: 'Analytics',
            description: 'Track campaign performance',
            icon: faChartLine
        },
        {
            title: 'Media Portfolio',
            description: 'Explore advertising spaces',
            icon: faBullhorn
        }
    ];

    return (
        <div className="home-page">
            <Header 
                onLoginClick={onLoginClick}
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
            />
            
            <section className="hero">
                <div className="hero-content">
                    <h1>AI-Powered Campaign Planning</h1>
                    <p>Let our intelligent AI system design the perfect marketing campaign for your business. Get personalized recommendations, budget optimization, and targeted media strategies in minutes.</p>
                    <div className="hero-buttons">
                        <button className="primary-button" onClick={handleQuickLaunch}>
                            Start Campaign Planning
                        </button>
                        <button className="secondary-button" onClick={handleAgencyRegistration}>
                            Register as Agency
                        </button>
                    </div>
                </div>
                <div className="media-channels-slider">
                    <Slider {...sliderSettings}>
                        {MEDIA_CHANNELS.map((channel, index) => (
                            <div key={index} className="slider-item">
                                <div className="channel-card" onClick={() => handleCategoryClick(channel.name.toUpperCase())}>
                                    <FontAwesomeIcon icon={channel.icon} className="channel-icon" />
                                    <h3>{channel.name}</h3>
                                    <p>{channel.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </section>

            <section className="features" id="features">
                <h2>Why Choose Us</h2>
                <div className="features-grid">
                    {features.map((feature, index) => (
                        <div 
                            key={index} 
                            className="feature-card"
                            onClick={
                                feature.title === 'Analytics' ? () => history.push('/analytics') :
                                feature.title === 'Quick Launch' ? feature.action :
                                feature.title === 'Media Portfolio' ? () => history.push('/view-sites') :
                                null
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            <FontAwesomeIcon icon={feature.icon} className="feature-icon" />
                            <h3>{feature.title}</h3>
                            <p>{feature.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            <section className="media-channels" id="solutions">
                <h2>Explore Media Channels</h2>
                <div className="channels-grid">
                    {mediaTypes.map((media, index) => (
                        <div 
                            key={index} 
                            className="channel-card"
                            onClick={() => handleCategoryClick(media.label)}
                        >
                            <div className="channel-icon">
                                <FontAwesomeIcon icon={media.icon} />
                            </div>
                            <h3>{media.label}</h3>
                            <p>{media.description}</p>
                        </div>
                    ))}
                </div>
            </section>

            <FAQ />

            <section className="contact-section" id="contact">
                <div className="contact-container">
                    <div className="contact-content">
                        <h2>Get in Touch</h2>
                        <p className="contact-subtitle">Ready to transform your advertising? Let's talk about your goals.</p>
                        
                        <div className="contact-cards">
                            <div className="contact-card">
                                <FontAwesomeIcon icon={faEnvelope} className="contact-icon" />
                                <h3>Email Us</h3>
                                <p>info@placead.in</p>
                                <a href="mailto:info@placead.in" className="contact-link">Send Email</a>
                            </div>
                            
                            <div className="contact-card">
                                <FontAwesomeIcon icon={faPhone} className="contact-icon" />
                                <h3>Call Us</h3>
                                <p>+91 89206 52901</p>
                                <a href="tel:+918920652901" className="contact-link">Call Now</a>
                            </div>
                            
                            <div className="contact-card">
                                <FontAwesomeIcon icon={faLocationDot} className="contact-icon" />
                                <h3>Visit Us</h3>
                                <p>Noida, Sector 62<br />Candor Techpark</p>
                                <a href="https://maps.google.com" target="_blank" rel="noopener noreferrer" className="contact-link">Get Directions</a>
                            </div>
                        </div>
                        
                        <div className="social-links">
                            <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebookF} />
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="cta">
                <div className="cta-content">
                    <h2>Ready to Start Advertising?</h2>
                    <p>Join thousands of businesses reaching their target audience</p>
                    <div className="cta-buttons">
                        <button className="cta-primary" onClick={handleQuickLaunch}>
                            <FontAwesomeIcon icon={faRocket} className="button-icon" />
                            <span>Launch Campaign</span>
                        </button>
                        <button className="cta-secondary" onClick={handleAgencyRegistration}>
                            <FontAwesomeIcon icon={faBuilding} className="button-icon" />
                            <span>Partner with Us</span>
                        </button>
                    </div>
                    <div className="cta-features">
                        <div className="cta-feature">
                            <FontAwesomeIcon icon={faChartLine} />
                            <span>Real-time Analytics</span>
                        </div>
                        <div className="cta-feature">
                            <FontAwesomeIcon icon={faGlobe} />
                            <span>Global Reach</span>
                        </div>
                        <div className="cta-feature">
                            <FontAwesomeIcon icon={faUsers} />
                            <span>24/7 Support</span>
                        </div>
                    </div>
                </div>
            </section>

            {/* Campaign Planner Modal */}
            {showCampaignPlanner && (
                <div className="modal-overlay">
                    <div className="modal-container">
                        <button 
                            className="modal-close-btn"
                            onClick={() => setShowCampaignPlanner(false)}
                        >
                            ×
                        </button>
                        <CampaignPlanner />
                    </div>
                </div>
            )}
        </div>
    );
}

export default HomePage;
