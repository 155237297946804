import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import api from '../api';
import './AgencyRegistration.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faRocket,
    faChartLine,
    faHandshake,
    faCreditCard,
    faFileContract,
    faVideo,
    faHeadset,
    faStar,
    faPercent,
    faQuoteLeft
} from '@fortawesome/free-solid-svg-icons';

const AgencyRegistration = () => {
    const [formData, setFormData] = useState({
        agencyName: '',
        contactName: '',
        email: '',
        phoneNumber: ''
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        // Clear error when user starts typing
        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const validateForm = () => {
        const newErrors = {};
        const requiredFields = [
            'agencyName',
            'contactName',
            'email',
            'phoneNumber'
        ];

        requiredFields.forEach(field => {
            if (!formData[field]) {
                newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1).replace(/([A-Z])/g, ' $1')} is required`;
            }
        });
        
        // Email validation
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (formData.email && !emailRegex.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
        }
        
        // Phone validation (basic)
        const phoneRegex = /^\d{10}$/;
        if (formData.phoneNumber && !phoneRegex.test(formData.phoneNumber)) {
            newErrors.phoneNumber = 'Please enter a valid 10-digit phone number';
        }
        
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = validateForm();
        
        if (Object.keys(newErrors).length === 0) {
            setIsSubmitting(true);
            try {
                // Format data to match backend expectations
                const formattedData = {
                    agencyName: formData.agencyName,
                    contactInfo: {
                        contactName: formData.contactName,
                        email: formData.email.toLowerCase(),
                        phoneNumber: formData.phoneNumber
                    }
                };
                
                const response = await api.post('/agencies/register', formattedData);
                console.log('Agency registration submitted:', response.data);
                
                // Clear form after successful submission
                setFormData({
                    agencyName: '',
                    contactName: '',
                    email: '',
                    phoneNumber: ''
                });
                
                // Show success message
                alert('Thank you for registering! We will review your application and contact you soon.');
                
                // Redirect to home page
                history.push('/');
            } catch (error) {
                console.error('Error submitting form:', error);
                const errorMessage = error.response?.data?.message || 'Failed to submit registration. Please try again.';
                alert(errorMessage);
            } finally {
                setIsSubmitting(false);
            }
        } else {
            setErrors(newErrors);
        }
    };

    return (
        <div className="agency-registration-page">
            <div className="hero-section">
                <div className="hero-content">
                    <div className="hero-text">
                        <h1>Grow With PlaceAd</h1>
                        <p>Join India's fastest growing advertising network and unlock new opportunities</p>
                    </div>
                    <div className="hero-stats">
                        <div className="stat-item">
                            <div className="stat-icon-wrapper">
                                <FontAwesomeIcon icon={faRocket} className="stat-icon" />
                            </div>
                            <div className="stat-content">
                                <h3>500+</h3>
                                <p>Partner Agencies</p>
                            </div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-icon-wrapper">
                                <FontAwesomeIcon icon={faChartLine} className="stat-icon" />
                            </div>
                            <div className="stat-content">
                                <h3>₹100Cr+</h3>
                                <p>Media Spent</p>
                            </div>
                        </div>
                        <div className="stat-item">
                            <div className="stat-icon-wrapper">
                                <FontAwesomeIcon icon={faHandshake} className="stat-icon" />
                            </div>
                            <div className="stat-content">
                                <h3>1000+</h3>
                                <p>Active Clients</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="agency-registration-container">
                <div className="form-section">
                    <div className="form-container">
                        <h2>Register Your Agency</h2>
                        <p className="form-subtitle">Start your journey with PlaceAd today</p>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="agencyName"
                                    value={formData.agencyName}
                                    onChange={handleInputChange}
                                    placeholder="Agency Name *"
                                    className={errors.agencyName ? 'error' : ''}
                                />
                                {errors.agencyName && <span className="error-message">{errors.agencyName}</span>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    name="contactName"
                                    value={formData.contactName}
                                    onChange={handleInputChange}
                                    placeholder="Contact Name *"
                                    className={errors.contactName ? 'error' : ''}
                                />
                                {errors.contactName && <span className="error-message">{errors.contactName}</span>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    placeholder="Email Address *"
                                    className={errors.email ? 'error' : ''}
                                />
                                {errors.email && <span className="error-message">{errors.email}</span>}
                            </div>

                            <div className="form-group">
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    value={formData.phoneNumber}
                                    onChange={handleInputChange}
                                    placeholder="Phone Number *"
                                    className={errors.phoneNumber ? 'error' : ''}
                                />
                                {errors.phoneNumber && <span className="error-message">{errors.phoneNumber}</span>}
                            </div>

                            <button 
                                type="submit" 
                                className="submit-button"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Submitting...' : 'Register Agency'}
                            </button>
                        </form>
                    </div>
                </div>

                <div className="why-partner-section">
                    <div className="section-container">
                        <h2 className="section-title">Why Partner With PlaceAd?</h2>
                        <p className="section-subtitle">Join other 500+ agencies who have partnered with us to grow.</p>
                        
                        <div className="benefits-grid">
                            <div className="benefit-card">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faCreditCard} className="benefit-icon" />
                                </div>
                                <div className="benefit-content">
                                    <h3>Zero Investment</h3>
                                    <p>Start without any upfront costs or hidden fees</p>
                                </div>
                            </div>
                            <div className="benefit-card">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faFileContract} className="benefit-icon" />
                                </div>
                                <div className="benefit-content">
                                    <h3>Revenue Sharing</h3>
                                    <p>Earn competitive commissions on every campaign</p>
                                </div>
                            </div>
                            <div className="benefit-card">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faVideo} className="benefit-icon" />
                                </div>
                                <div className="benefit-content">
                                    <h3>Premium Inventory</h3>
                                    <p>Access exclusive media across all channels</p>
                                </div>
                            </div>
                            <div className="benefit-card">
                                <div className="icon-wrapper">
                                    <FontAwesomeIcon icon={faHeadset} className="benefit-icon" />
                                </div>
                                <div className="benefit-content">
                                    <h3>24/7 Support</h3>
                                    <p>Get dedicated support to grow your business</p>
                                </div>
                            </div>
                        </div>

                        <div className="agency-types">
                            <h4>Perfect For:</h4>
                            <div className="agency-tags">
                                <span className="agency-tag">Digital Marketing</span>
                                <span className="agency-tag">Creative</span>
                                <span className="agency-tag">PR</span>
                                <span className="agency-tag">Outdoor</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="launch-benefits-section">
                <div className="section-container">
                    <h2 className="section-title">Launch Partner Benefits</h2>
                    <p className="section-subtitle">Join early and unlock exclusive advantages</p>
                    <div className="benefits-grid">
                        <div className="benefit-card">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={faStar} className="benefit-icon" />
                            </div>
                            <div className="benefit-content">
                                <h3>Priority Access</h3>
                                <p>Get early access to new features and premium inventory before others</p>
                            </div>
                        </div>
                        <div className="benefit-card">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={faPercent} className="benefit-icon" />
                            </div>
                            <div className="benefit-content">
                                <h3>Special Commission</h3>
                                <p>Enjoy higher commission rates as one of our first 50 partner agencies</p>
                            </div>
                        </div>
                        <div className="benefit-card">
                            <div className="icon-wrapper">
                                <FontAwesomeIcon icon={faHeadset} className="benefit-icon" />
                            </div>
                            <div className="benefit-content">
                                <h3>Dedicated Support</h3>
                                <p>Get personalized onboarding and priority customer support</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="success-stories-section">
                <div className="section-container">
                    <h2 className="section-title">Success Stories</h2>
                    <p className="section-subtitle">Join hundreds of agencies who have transformed their business with PlaceAd</p>
                    
                    <div className="stories-grid">
                        <div className="story-card">
                            <div className="quote-icon">
                                <FontAwesomeIcon icon={faQuoteLeft} className="icon" />
                            </div>
                            <p className="quote">"PlaceAd helped us scale our media buying operations by 300% in just 6 months. Their platform and support are exceptional."</p>
                            <div className="author">
                                <div className="author-info">
                                    <h4>Priya Sharma</h4>
                                    <p>Digital Marketing Head, MediaCraft</p>
                                </div>
                                <div className="metrics">
                                    <span className="metric">
                                        <strong>3x</strong>
                                        <span>Growth</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="story-card">
                            <div className="quote-icon">
                                <FontAwesomeIcon icon={faQuoteLeft} className="icon" />
                            </div>
                            <p className="quote">"The revenue sharing model and zero investment approach made it easy for us to start. Now we're handling campaigns worth crores."</p>
                            <div className="author">
                                <div className="author-info">
                                    <h4>Rajesh Kumar</h4>
                                    <p>Founder, AdVentures Media</p>
                                </div>
                                <div className="metrics">
                                    <span className="metric">
                                        <strong>₹2Cr+</strong>
                                        <span>Campaigns</span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="story-card">
                            <div className="quote-icon">
                                <FontAwesomeIcon icon={faQuoteLeft} className="icon" />
                            </div>
                            <p className="quote">"Access to premium inventory and their support team's expertise has helped us win and retain major clients consistently."</p>
                            <div className="author">
                                <div className="author-info">
                                    <h4>Amit Patel</h4>
                                    <p>CEO, MediaMax Solutions</p>
                                </div>
                                <div className="metrics">
                                    <span className="metric">
                                        <strong>15+</strong>
                                        <span>Major Clients</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgencyRegistration;